import * as React from 'react';
import { Route } from 'react-router-dom';
import Loadable from 'react-loadable';
import { Loading } from 'react-admin';

const Dashboard = Loadable({
  loader: () => import('./components/dashboard/dashboard'),
  loading: Loading,
});
const Profile = Loadable({
  loader: () => import('./components/profile'),
  loading: Loading,
});
const LogoutRoute = Loadable({
  loader: () => import('./components/logout/route'),
  loading: Loading,
});
const SettingsPage = Loadable({
  loader: () => import('./components/settings'),
  loading: Loading,
});
const ClonePage = Loadable({
  loader: () => import('./components/clone'),
  loading: Loading,
});
export default [
  <Route exact path="/profile" component={Profile} />,
  <Route exact path="/logout" component={LogoutRoute} />,
  <Route exact path="/home" render={() => <Dashboard />} />,
  <Route exact path="/settings" component={SettingsPage} />,
  <Route exact path="/clone" component={ClonePage} />,
];
