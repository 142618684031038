import React from 'react';
import { render } from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';


console.log('env', process.env.NODE_ENV);
console.log('custom_env', process.env.REACT_APP_CUSTOM_NODE_ENV);

render(<App />, document.getElementById('root'));
// </React.StrictMode>,
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
