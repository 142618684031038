import {
  fetchUtils,
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
} from 'react-admin';
import sanitizeText from '../utils/functions/sanitizeText';
import { translationFields } from '../utils/variables';

const { sanitizeUrl } = require('@braintree/sanitize-url');
const get = require('lodash.get');
const set = require('lodash.set');
/**
 * Maps react-admin queries to a simple REST API
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (
  apiUrl,
  httpClient = fetchUtils.fetchJson,
  uploadFields = [],
  language,
) => {
  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */

  const contains = (a, b) => {
    // array matches
    if (Array.isArray(b)) {
      return b.some((x) => a.indexOf(x) > -1);
    }
    // string match
    return a.indexOf(b) > -1;
  };

  const convertDataRequestToHTTP = (type, resource, params) => {
    let url = '';
    const options = {};
    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
        url = `${apiUrl}/${resource}?${adjustQueryForStrapi(params)}`;
        break;
      case GET_ONE:
        url = `${apiUrl}/${resource}/${params.id}/?_publicationState=preview`;
        break;
      case UPDATE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'PUT';

        const newObj = updateContent(params.data, true);
        // Omit created_at/updated_at(RDS) and createdAt/updatedAt(Mongo) in request body
        const { created_at, updated_at, createdAt, updatedAt, ...data } = newObj;
        options.body = JSON.stringify(data);
        break;
      }
      case CREATE: {
        const newObj = updateContent(params.data, false);
        url = `${apiUrl}/${resource}`;
        options.method = 'POST';
        options.body = JSON.stringify(newObj);
        break;
      }
      case DELETE:
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'DELETE';
        break;
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
  };

  const adjustQueryForStrapi = (params) => {
    /*
        params = { 
            pagination: { page: {int} , perPage: {int} }, 
            sort: { field: {string}, order: {string} }, 
            filter: {Object}, 
            target: {string}, (REFERENCE ONLY)
            id: {mixed} (REFERENCE ONLY)
        }
        */

    // Handle SORTING
    const s = params.sort;
    const sort = s.field === '' ? '_sort=updated_at:DESC' : `_sort=${s.field}:${s.order}`;

    // Handle FILTER
    const f = params.filter;
    let filter = '';
    const keys = Object.keys(f);
    for (let i = 0; i < keys.length; i++) {
      // react-admin uses q filter in several components and strapi use _q
      if (keys[i] === 'q' && f[keys[i]] !== '') {
        if (Array.isArray(f[keys[i]])) {
          for (const val in f[keys[i]]) {
            filter += `${keys[i]}=${f[keys[i]][val]}${keys[i + 1][val] ? '&' : ''}`;
          }
        } else {
          filter += `_q=${f[keys[i]]}${keys[i + 1] ? '&' : ''}`;
        }
      } else {
        if (Array.isArray(f[keys[i]])) {
          f[keys[i]].forEach((territory, index) => {
            filter += `${keys[i]}=${territory}${
              index < f[keys[i]].length - 1 ? '&' : keys[i + 1] ? '&' : ''
            }`;
          });
        }
        filter += `${keys[i]}=${f[keys[i]]}${keys[i + 1] ? '&' : ''}`;
      }
    }
    if (params.id && params.target && params.target.indexOf('_id') !== -1) {
      const target = params.target.substring(0, params.target.length - 3);
      filter += `&${target}=${params.id}`;
    }

    // Handle PAGINATION
    const { page, perPage } = params.pagination;
    const start = (page - 1) * perPage;
    const limit = perPage; // for strapi the _limit params indicate the amount of elements to return in the response
    const range = `_start=${start}&_limit=${limit}`;

    return `${sort}&${range}&${filter}`;
  };

  /*
  const keyify = (obj = {}, prefix = '') =>
    Object.keys(obj).reduce((res, el) => {
      if (Array.isArray(obj[el])) {
        return res;
      }
      if (typeof obj[el] === 'object' && obj[el] !== null) {
        return [...res, prefix + el, ...keyify(obj[el], `${prefix + el}.`)];
      }
      return [...res, prefix + el];
    }, []);

    */

  const keyify = (obj, prefix = '') =>
    Object.keys(obj).reduce((res, el) => {
      if (Array.isArray(obj[el])) {
        const objectsInArray = obj[el].filter((el) => typeof el === 'object');
        if (objectsInArray.length > 0) {
          let objectKeys = [];
          objectsInArray.map((object, index) => {
            objectKeys = objectKeys.concat(keyify(object, `${prefix + el}[${index}].`));
          });
          return [...res, ...new Set(objectKeys)];
        }
        return [...res, prefix + el];
      }
      if (typeof obj[el] === 'object' && obj[el] !== null) {
        return [...res, prefix + el, ...keyify(obj[el], `${prefix + el}.`)];
      }

      return [...res, prefix + el];
    }, []);
  // Determines if there are new files to upload
  const determineUploadFieldNames = (params) => {
    if (!params.data) return [];

    // Check if the field names are mentioned in the uploadFields
    // and verify there are new files being added
    // console.log('params.data: ', params.data);
    // console.log('keyify(params.data): ', keyify(params.data));

    const requestUplaodFieldNames = [];
    keyify(params.data).forEach((field) => {
      // let fieldData = params.data[field];
      // console.log('fieldData1: ', field, params.data, uploadFields);
      const tempField = field.replace(/\[(.*?)\]/, '');
      // console.log('tempField: ', tempField);
      if (uploadFields.includes(tempField)) {
        let fieldData = get(params.data, field);
        /// console.log('field: ', field);
        /// console.log('fieldData: ', fieldData);
        // console.log("fieldname:", field)
        fieldData = !Array.isArray(fieldData) ? [fieldData] : fieldData;
        fieldData.filter((f) => f && f.rawFile instanceof File).length > 0 &&
          requestUplaodFieldNames.push(field);
      }
    });

    // Return an array of field names where new files are added
    // console.log('requestUplaodFieldNames: ', requestUplaodFieldNames);
    return requestUplaodFieldNames;
  };

  /*
  function ref(obj = {}, str = '') {
    str = str.split('.');
    for (let i = 0; i < str.length; i++) obj = obj[str[i]];
    return obj;
  }
  function set(obj = {}, str = '', val = '') {
    str = str.split('.');
    while (str.length > 1) obj = obj[str.shift()];
    return (obj[str.shift()] = val);
  }
  */
  // Handles file uploading for CREATE and UPDATE types
  const handleFileUpload = (type, resource, params, uploadFieldNames) => {
    const { created_at, updated_at, createdAt, updatedAt, ...data } = params.data;
    const id = type === UPDATE ? `/${params.id}` : '';
    let res = resource;
    if (resource.indexOf('upload/files') == 0) {
      res = resource.replace('/files', '');
    }
    const { currentLocale } = params.data;
    const url = `${apiUrl}/${res}${id}`;
    const requestMethod = type === UPDATE ? 'PUT' : 'POST';
    const formData = new FormData();

    for (const fieldName of uploadFieldNames) {
      const fieldData = get(params.data, fieldName);
      // let fieldData = params.data[fieldName];
      // console.log('fieldData: ', fieldData);
      const multiple = Array.isArray(fieldData);
      // console.log('multiple: ', multiple);
      // fieldData = !Array.isArray(fieldData) ? [fieldData] : fieldData;
      // console.log('fieldData2: ', fieldData);
      const existingFileIds = multiple ? [] : null;

      // for (let item of fieldData) {
      // item.rawFile instanceof File
      // ? formData.append(`files.${fieldName}`, item.rawFile)
      // : existingFileIds.push(item.id || item._id);
      // }
      if (multiple) {
        for (const item of fieldData) {
          // console.log('item: ', item);
          if (!item.rawFile instanceof File) {
            existingFileIds.push(item.id || item._id);
          } else {
            formData.append(`files.${fieldName}`, item.rawFile);
            if (currentLocale) {
              formData.append(`files.${fieldName}_${currentLocale}`, item.rawFile);
            }
          }
        }
        // console.log('existingFileIds: ', existingFileIds);
        // data[fieldName] = [...existingFileIds];
        set(data, fieldName, [...existingFileIds]);
      } else {
        if (!fieldData.rawFile instanceof File) {
          existingFileIds = fieldData.id || fieldData._id;
        } else if (resource.indexOf('upload/files') == 0) {
          formData.append(`${fieldName}`, fieldData.rawFile);
        } else {
          formData.append(`files.${fieldName}`, fieldData.rawFile);
          if (currentLocale) {
            formData.append(`files.${fieldName}_${currentLocale}`, fieldData.rawFile);
          }
        }
        // console.log('existingFileIds: ', existingFileIds);
        // data[fieldName] = existingFileIds;
        set(data, fieldName, existingFileIds);
      }
    }
    formData.append('data', JSON.stringify(data));
    // console.log('formData: ', formData);

    return httpClient(url, {
      method: requestMethod,
      body: formData,
    }).then((response) => ({ data: replaceRefObjectsWithIds(response.json) }));
  };

  // Replace reference objects with reference object IDs
  const replaceRefObjectsWithIds = (_json) => {
    if (Array.isArray(_json)) {
      let newJson = _json.map((json) => {
        Object.keys(json).forEach((key) => {
          const fd = json[key]; // field data
          const referenceKeys = [];
          if (fd && (fd.id || fd._id) && !fd.mime && !fd.isComponent && !fd.__component) {
            json[key] = fd.id || fd._id;
          } else if (
            Array.isArray(fd) &&
            fd.length > 0 &&
            fd[0] &&
            !fd[0].mime &&
            !fd[0].isComponent &&
            !fd[0].__component
          ) {
            fd.map((item) => referenceKeys.push(item.id || item._id));
            json[key] = referenceKeys;
          }
        });
        return json;
      });
      if (Array.isArray(newJson)) {
        newJson = newJson[0];
      }
      return newJson;
    }
    const json = _json;
    Object.keys(json).forEach((key) => {
      const fd = json[key]; // field data
      const referenceKeys = [];
      if (fd && (fd.id || fd._id) && !fd.mime && !fd.isComponent && !fd.__component) {
        json[key] = fd.id || fd._id;
      } else if (
        Array.isArray(fd) &&
        fd.length > 0 &&
        fd[0] &&
        !fd[0].mime &&
        !fd[0].isComponent &&
        !fd[0].__component
      ) {
        fd.map((item) => referenceKeys.push(item.id || item._id));
        json[key] = referenceKeys;
      }
    });
    return json;
  };

  const santizeHTMLInputs = (_json) => {
    // const jsonString = JSON.stringify(_json);
    // const sanitizeString = sanitizeText(jsonString);
    // const json = JSON.parse(sanitizeString);
    if (Array.isArray(_json)) {
      const newJson = _json.map((json) => {
        Object.keys(json).forEach((key) => {
          const fd = json[key]; // field data

          if (
            fd &&
            (key === 'content' ||
              key === 'proseMirrorContent' ||
              key === 'actionMessage' ||
              key === 'fullBio' ||
              key === 'locationPreviewDetails')
          ) {
            if (fd && fd !== 'undefined' && fd !== 'null') {
              json[key] = sanitizeText(fd);
            } else {
              json[key] = null;
            }
          }
          if (fd && key === 'url') {
            if (fd && fd !== 'undefined' && fd !== 'null') {
              json[key] = sanitizeUrl(fd);
            } else {
              json[key] = null;
            }
          }
        });
        return json;
      });

      return newJson;
    }
    const json = _json;
    Object.keys(json).forEach((key) => {
      const fd = json[key]; // field data

      if (
        fd &&
        (key === 'content' ||
          key === 'proseMirrorContent' ||
          key === 'actionMessage' ||
          key === 'fullBio' ||
          key === 'locationPreviewDetails')
      ) {
        if (fd && fd !== 'undefined' && fd !== 'null') {
          json[key] = sanitizeText(fd);
        } else {
          json[key] = null;
        }
      }
      if (fd && key === 'url') {
        if (fd && fd !== 'undefined' && fd !== 'null') {
          json[key] = sanitizeUrl(fd);
        } else {
          json[key] = null;
        }
      }
    });

    return json;

    // return json;
  };

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} Data response
   */
  const convertHTTPResponse = (response, type, resource, params) => {
    let { headers, json, total } = response;
    // console.log('json1: ', json);
    languageTranslation(json, language);
    json = santizeHTMLInputs(json);
    // console.log('json2: ', json);
    switch (type) {
      case GET_ONE:
        return { data: replaceRefObjectsWithIds(json) };
      case GET_LIST:
      case GET_MANY_REFERENCE:
        return {
          data: json,
          total,
        };

      case CREATE:
        return { data: { ...params.data, id: json.id } };
      case DELETE:
        return { data: { id: null } };
      default:
        return { data: replaceRefObjectsWithIds(json) };
    }
  };

  const getAllKeys = (obj) => {
    // // console.log('Object.keys(obj): ', Object.keys(obj));
    // // console.log('stringObj: ', stringObj);

    const keys = keyifyAll(obj);

    return keys;
  };

  const findDuplicates = (arr = [], language) => {
    const sorted_arr = arr.slice().sort(); // You can define the comparing function here.
    // JS by default uses a crappy string compare.
    // (we use slice to clone the array so the
    // original array won't be modified)
    const results = [];
    for (let i = 0; i < sorted_arr.length; i++) {
      const str = sorted_arr[i];
      if (str.endsWith(`_${language}`)) {
        if (results.indexOf(str.split(`_${language}`)[0]) == -1) {
          results.push(str.split(`_${language}`)[0]);
        }
      }
    }
    return results;
  };

  const languageTranslation = (obj, language = '') => {
    let keys = [];
    if (Array.isArray(obj)) {
      for (let j = 0; j < obj.length; j++) {
        keys = getAllKeys(obj[j]);
        var duplicateKeys = findDuplicates(keys, language);
        for (var key in duplicateKeys) {
          stringDotNotation(obj[j], duplicateKeys[key], language);
        }
      }
    } else if (obj != undefined) {
      keys = getAllKeys(obj);
      var duplicateKeys = findDuplicates(keys, language);
      for (var key in duplicateKeys) {
        stringDotNotation(obj, duplicateKeys[key], language);
      }
    }

    return obj;
  };

  const updateContent = (obj, isUpdate) => {
    // console.log('obj: ', obj);
    const originalLocale = obj.defaultLocale
      ? obj.languages[obj.defaultLocale].code
      : 'en';
    // console.log('originalLocale: ', originalLocale);
    const currentLocale = obj.currentLocale || 'en';
    // console.log('currentLocale: ', currentLocale);
    const keys = getAllKeys(obj);
    // var duplicateKeys = findDuplicates(keys);
    for (const key in keys) {
      const val = keys[key].split('.');
      if (val.length > 0) {
        if (translationFields.includes(val[val.length - 1])) {
          updateStringDotNotation(
            obj,
            keys[key],
            currentLocale,
            originalLocale,
            isUpdate,
          );
        }
      } else if (translationFields.includes(keys[key])) {
        updateStringDotNotation(obj, keys[key], currentLocale, originalLocale, isUpdate);
      }
    }
    delete obj.languages;
    // delete obj.currentLocale;
    return obj;
  };
  const updateStringDotNotation = (
    obj,
    str = '',
    currentLocale = 'en',
    originalLocale = 'en',
    isUpdate,
  ) => {
    str = str.split('.');
    for (let i = 0; i < str.length; i++) {
      if (str.length - 1 == i) {
        if (Array.isArray(obj)) {
          for (var j = 0; j < obj.length; j++) {
            // console.log('str[i]: ', str[i]);
            // console.log('language: ', language);
            if (obj[j] && str[i]) {
              // obj[j][str[i]]
              obj[j][`${str[i]}_${currentLocale}`] = obj[j][str[i]];
              if (isUpdate) {
                // if (str[i] === "title" || str[i] === "name") {
                //    if (obj[j][str[i]])
                //        delete obj[j][str[i]];
                // }
                if (currentLocale !== originalLocale) {
                  if (obj[j][str[i]] !== undefined) delete obj[j][str[i]];
                }
              }
            }

            // if (isUpdate && (str[i] == 'title' || str[i] == 'name')) {
            // delete obj[j][str[i]];
            // }
          }
        } else {
          if (obj && str[i]) {
            // obj[str[i]]
            obj[`${str[i]}_${currentLocale}`] = obj[str[i]];
            if (isUpdate) {
              // if (str[i] === "title" || str[i] === "name") {
              //    if (obj[str[i]])
              //        delete obj[str[i]];
              // }
              if (currentLocale !== originalLocale) {
                if (obj[str[i]] !== undefined) delete obj[str[i]];
              }
            }
          }
          // if (isUpdate && (str[i] == 'title' || str[i] == 'name')) {
          // delete obj[str[i]];
          // }
        }
      }
      if (obj && Array.isArray(obj)) {
        if (str.length - 1 != i) {
          const arr = [];
          for (var j = 0; j < obj.length; j++) {
            if (obj[j] && obj[j][str[i]]) arr.push(obj[j][str[i]]);
          }
          obj = arr;
        }
      } else if (obj && obj[str[i]]) obj = obj[str[i]];
    }
    return obj;
  };

  function removeLinebreaks(str) {
    if (str) {
      str = str.toString();
      str = str.trim();
      return str.replace(/[\r\n]+/gm, '');
    }
    return '';
  }

  const stringDotNotation = (obj, str = '', language = '') => {
    str = str.split('.');
    for (let i = 0; i < str.length; i++) {
      if (str.length - 1 == i) {
        if (Array.isArray(obj)) {
          for (var j = 0; j < obj.length; j++) {
            if (obj[j]) {
              const trimLine = removeLinebreaks(obj[j][`${str[i]}_${language}`]);
              if (
                obj[j][`${str[i]}_${language}`] !== 'null' &&
                obj[j][`${str[i]}_${language}`] !== 'undefined' &&
                trimLine.length > 0 &&
                trimLine != '<p></p>' &&
                trimLine != '<p><br></p>'
              ) {
                obj[j][str[i]] = obj[j][`${str[i]}_${language}`] || obj[j][str[i]];
              } else {
                obj[j][str[i]] = obj[j][str[i]];
              }
            }
          }
        } else if (obj) {
          const trimLine = removeLinebreaks(obj[`${str[i]}_${language}`]);
          if (
            obj[`${str[i]}_${language}`] !== 'null' &&
            obj[`${str[i]}_${language}`] !== 'undefined' &&
            trimLine.length > 0 &&
            trimLine != '<p></p>' &&
            trimLine != '<p><br></p>'
          ) {
            obj[str[i]] = obj[`${str[i]}_${language}`] || obj[str[i]];
          } else {
            obj[str[i]] = obj[str[i]];
          }
        }
      }
      if (Array.isArray(obj)) {
        if (str.length - 1 != i) {
          const arr = [];
          for (var j = 0; j < obj.length; j++) {
            if (obj[j] != null) {
              if (Array.isArray(obj[j][str[i]])) {
                arr.push(...obj[j][str[i]]);
              } else {
                arr.push(obj[j][str[i]]);
              }
            }
          }
          obj = arr;
        }
      } else {
        obj = obj[str[i]];
      }
      // obj = obj[str[i]];
    }
    return obj;
  };

  const keyifyAll = (obj = {}, prefix = '') => {
    if (!obj || typeof obj !== 'object') return [];
    return Object.keys(obj).reduce((res, el) => {
      const elDisplayName = `${prefix}${el}`;
      if (Array.isArray(obj[el])) {
        const objectsInArray = obj[el].filter((el) => typeof el === 'object');
        if (objectsInArray.length > 0) {
          let objectKeys = [];
          objectsInArray.map((object) => {
            objectKeys = objectKeys.concat(keyifyAll(object, `${prefix + el}.`));
          });
          return [...res, ...new Set(objectKeys)];
        }
        return [...res, elDisplayName];
      }
      if (typeof obj[el] === 'object' && obj[el] !== null) {
        if (uploadFields.includes(el)) {
          return [...res, prefix + el];
        }
        return [...res, prefix + el, ...keyifyAll(obj[el], `${prefix + el}.`)];
      }
      return [...res, elDisplayName];
    }, []);
  };

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return (type, resource, params) => {
    // console.log('resource before: ', resource);
    resource = contains(resource, '*') ? resource.split('*')[0] : resource;
    // console.log('resource after: ', resource);
    // Handle file uploading
    const uploadFieldNames = determineUploadFieldNames(params);
    if (uploadFieldNames.length > 0) {
      return handleFileUpload(type, resource, params, uploadFieldNames);
    }

    // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    if (type === UPDATE_MANY) {
      return Promise.all(
        params.ids.map((id) => {
          // Omit created_at/updated_at(RDS) and createdAt/updatedAt(Mongo) in request body
          const { created_at, updated_at, createdAt, updatedAt, ...data } = params.data;
          return httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'PUT',
            body: JSON.stringify(data),
          });
        }),
      ).then((responses) => ({
        data: responses.map((response) => response.json),
      }));
    }
    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'DELETE',
          }),
        ),
      ).then((responses) => ({
        data: responses.map((response) => response.json),
      }));
    }
    // strapi doesn't handle filters in GET route
    if (type === GET_MANY) {
      return Promise.all(
        params.ids.map((i) =>
          httpClient(`${apiUrl}/${resource}/${i.id || i._id || i}`, {
            method: 'GET',
          }),
        ),
      ).then((responses) => {
        return {
          data: responses.map((response) => {
            // console.log('resource: ', resource);
            // console.log('response: ', response);
            let jsonResponse = response.json;
            if (resource === 'users-permissions/roles') {
              jsonResponse = response.json.role;
            }
            // console.log('jsonResponse: ', jsonResponse);
            return jsonResponse;
          }),
        };
      });
    }

    const { url, options } = convertDataRequestToHTTP(type, resource, params);

    // Get total via model/count endpoint
    if (type === GET_MANY_REFERENCE || type === GET_LIST) {
      const { url: urlForCount } = convertDataRequestToHTTP(
        type,
        `${resource}/count`,
        params,
      );
      const httpRequests = [];
      httpRequests.push(httpClient(url, options));
      if (resource !== 'users-permissions/roles') {
        httpRequests.push(httpClient(urlForCount, options));
      }
      return Promise.allSettled(httpRequests).then((promises) => {
        // console.log('promises: ', promises);
        const payload = promises[0].value;
        if (resource === 'users-permissions/roles') {
          payload.json = payload.json.roles;
        }

        const response = {
          // ...promises[0],
          ...payload,
          // Add total for further use
          // total: parseInt(promises[1].json, 10),
          total:
            promises[1] && promises[1].status === 'fulfilled'
              ? parseInt(promises[1].value.json, 10)
              : 0,
        };
        return convertHTTPResponse(response, type, resource, params);
      });
    }
    return httpClient(url, options).then((response) =>
      convertHTTPResponse(response, type, resource, params),
    );
  };
};
