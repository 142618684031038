const styles = theme => ({
  root: {
    display: 'flex',
    // paddingTop: theme.spacing(2),
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    // width: '150%',
  },
  image: {
    width: '100%',
    objectFit: 'contain',
  },
});

export default styles;
