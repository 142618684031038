import * as React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  CloneButton,
  EditButton,
  DateField,
  ReferenceArrayField,
  SingleFieldList,
  ListButton,
  ChipField,
} from 'react-admin'; // eslint-disable-line import/no-unresolved
import WizardListingTitle from './title';
import { admin } from '../../utils/variables';
import Aside from './aside';

const ShowActions = (props) => {
  const { basePath, data, hasEdit, hasCreate, hasList } = props;
  return (
    <TopToolbar>
      {hasList && <ListButton basePath={basePath} record={data} />}
      {hasEdit && <EditButton basePath={basePath} record={data} />}
    </TopToolbar>
  );
};
const WizardListingShow = ({ permissions, ...props }) => (
  <Show
    {...props}
    aside={<Aside />}
    title={<WizardListingTitle show />}
    actions={<ShowActions permissions={permissions} {...props} />}
  >
    <SimpleShowLayout>
      <TextField source="id" label="resources.wizardListings.fields.id" />

      <TextField source="title" label="resources.wizardListings.fields.title" />

      <TextField source="slug" label="resources.wizardListings.fields.slug" />
      <TextField
        source="materialIcon"
        label="resources.wizardListings.fields.materialIcon"
      />
      <TextField source="wizardId" label="resources.wizardListings.fields.wizardId" />
      <ReferenceArrayField
        label="resources.wizardListings.fields.territories"
        source="territories"
        reference="territories"
        className="ra-field-100"
        link="show"
      >
        <SingleFieldList linkType="show">
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <TextField
        label="resources.wizardListings.fields.description"
        source="description"
        className="ra-field-clear ra-field-100"
      />

      <DateField
        label="resources.wizardListings.fields.createdAt"
        source="createdAt"
        className="ra-field-50"
      />
      <DateField
        label="resources.wizardListings.fields.updatedAt"
        source="updatedAt"
        className="ra-field-50"
      />
      <DateField
        label="resources.wizardListings.fields.publishedAt"
        source="published_at"
        className="ra-field-50"
      />
    </SimpleShowLayout>
  </Show>
);

export default WizardListingShow;
