import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Resource, useTranslate, usePermissions, AdminUI, Loading } from 'react-admin';
import Loadable from 'react-loadable';
import MoreIcon from '@material-ui/icons/More';
import ViewCompactIcon from '@material-ui/icons/ViewCompact';
import articles from './resources/articles';
import products from './resources/products';
// import comments from './resources/comments';
// import bookmarks from './resources/bookmarks';
// import favourites from './resources/favourites';
// import shares from './resources/shares';
import articleCategories from './resources/article-categories';
import tags from './resources/tags';
import backlinks from './resources/backlinks';
import authors from './resources/authors';
import users from './resources/users';
import modals from './resources/modals';
import genericContents from './resources/generic-contents';
import extraContents from './resources/extra-contents';
import territories from './resources/territories';
import icons from './resources/icons';
import lobCategories from './resources/lob-categories';
import downloads from './resources/downloads';
import downloadCategories from './resources/download-categories';
import downloadTypes from './resources/download-types';
import employees from './resources/employees';
import presenters from './resources/presenters';
import employeeGroupCategories from './resources/employee-group-categories';
import employeeGroups from './resources/employee-groups';
import employeeTypes from './resources/employee-types';
import awardTypes from './resources/award-types';
import locations from './resources/locations';
import faqs from './resources/faqs';
import faqCategories from './resources/faq-categories';
import faqTypes from './resources/faq-types';
import events from './resources/events';
import eventTypes from './resources/event-types';
import locationCategories from './resources/location-categories';
import locationTypes from './resources/location-types';
import companies from './resources/companies';
import companyCategories from './resources/company-categories';
import awards from './resources/awards';
import awardCategories from './resources/award-categories';
import companyListings from './resources/company-listings';
import overrides from './resources/overrides';
import pages from './resources/pages';
import actionBars from './resources/action-bars';
import navigationBars from './resources/navigation-bars';
import headerSection from './resources/header-sections';
import headerDropdown from './resources/header-dropdowns';
import footerSection from './resources/footer-sections';
import productCategories from './resources/product-categories';
import corporateStatement from './resources/corporate-statement';
import coreValues from './resources/core-values';
import glossaryItems from './resources/glossary-items';
import glossaries from './resources/glossaries';
import uploadFiles from './resources/uploadFiles';
// import c360GaEvents from './resources/c360-ga-events';
import wizardListings from './resources/wizard-listings';
import helpArticles from './resources/help-articles';
import helpArticleCategories from './resources/help-article-categories';

import MyLayout from './components/layout';
import customRoutes from './customRoutes';

import {
  admin,
  editor,
  author,
  contributor,
  viewer,
  myGGEditor,
} from './utils/variables';

const NotFound = Loadable({
  loader: () => import('./components/common/notFound'),
  loading: Loading,
});
const MyLogoutButton = Loadable({
  loader: () => import('./components/logout'),
  loading: () => null,
});
const Dashboard = Loadable({
  loader: () => import('./components/dashboard'),
  loading: Loading,
});
const SettingsPage = Loadable({
  loader: () => import('./components/settings'),
  loading: Loading,
});
const MyLoginPage = Loadable({
  loader: () => import('./components/login'),
  loading: Loading,
});

const defaultRoleAccessControls = {
  [admin]: {
    list: true,
    edit: true,
    show: true,
    create: true,
  },
  [editor]: {
    list: true,
    edit: true,
    show: true,
    create: true,
  },
  [author]: {
    list: true,
    edit: true,
    show: true,
    create: true,
  },
  [contributor]: {
    list: true,
    edit: true,
    show: true,
    create: true,
  },
  [viewer]: {
    list: true,
    edit: false,
    show: true,
    create: false,
  },
  [myGGEditor]: {
    list: true,
    edit: true,
    show: true,
    create: true,
  },
};

const getViews = (resources, permissions, roleAccessControls, rolePreset = 'default') => {
  // console.log('roleAccessControls: ', roleAccessControls);
  // console.log('permissions: ', permissions);
  const finalResources = resources;
  if (!permissions) return {};
  const roleAccessControl = roleAccessControls[permissions];

  if (!roleAccessControl) return finalResources;
  Object.keys(roleAccessControl).map((key) => {
    // console.log('key: ', key);
    if (rolePreset === 'onlyAdmin' && permissions !== admin) {
      finalResources[key] = null;
      return null;
    }
    if (
      rolePreset === 'onlyAdminEditor' &&
      permissions !== admin &&
      permissions !== editor
    ) {
      finalResources[key] = null;
      return null;
    }
    if (
      rolePreset === 'onlyAdminEditorCanChange' &&
      permissions !== admin &&
      permissions !== editor &&
      (key === 'create' || key === 'edit')
    ) {
      finalResources[key] = null;
      return null;
    }

    if (
      rolePreset === 'onlyAdminEditorMyGGEditorCanChange' &&
      permissions !== admin &&
      permissions !== editor &&
      permissions !== myGGEditor &&
      (key === 'create' || key === 'edit')
    ) {
      finalResources[key] = null;
      return null;
    }

    if (
      rolePreset === 'onlyAdminCanChange' &&
      permissions !== admin &&
      (key === 'create' || key === 'edit')
    ) {
      finalResources[key] = null;
      return null;
    }

    if (
      rolePreset === 'onlyAdminCanChangeAndViewList' &&
      permissions !== admin &&
      (key === 'create' || key === 'list')
    ) {
      finalResources[key] = null;
      return null;
    }

    // console.log('finalResources: ', finalResources);
    // console.log(' roleAccessControl[key] : ', roleAccessControl[key]);
    // console.log('roleAccessControl: ', roleAccessControl);
    finalResources[key] = roleAccessControl[key] ? resources[key] : null;
    return null;
  });
  return finalResources;
};

const Routes = ({ theme }) => {
  // console.log('props routes: ', props);
  const translate = useTranslate();
  // const { loading: authLoading, authenticated, loaded } = useAuthState();

  const { loading, permissions } = usePermissions();
  // const { loading, authenticated } = useAuthState();

  // console.log('language: ', language);
  // console.log('permissions: ', permissions);
  // if (!permissions) return <div>Loading...</div>;

  if (loading) {
    return <Loading />;
  }

  // const { permissions } = props;
  // console.log('permissions: ', permissions);

  const finalHelpArticleViews = getViews(
    helpArticles,
    permissions,
    defaultRoleAccessControls,
  );
  const finalHelpArticleCategoryViews = getViews(
    helpArticleCategories,
    permissions,
    defaultRoleAccessControls,
    'onlyAdminEditorMyGGEditorCanChange',
  );
  const finalAuthorViews = getViews(
    authors,
    permissions,
    defaultRoleAccessControls,
    'onlyAdminCanChangeAndViewList',
  );

  const finalArticleViews = getViews(articles, permissions, defaultRoleAccessControls);
  // const finalRolesViews = getViews(roles, permissions, defaultRoleAccessControls);
  const finalArticleCategoryViews = getViews(
    articleCategories,
    permissions,
    defaultRoleAccessControls,
    'onlyAdminEditorCanChange',
  );
  const finalProductViews = getViews(products, permissions, defaultRoleAccessControls);
  const finalLobCategoryViews = getViews(
    lobCategories,
    permissions,
    defaultRoleAccessControls,
    'onlyAdminEditorCanChange',
  );
  const finalDownloadViews = getViews(downloads, permissions, defaultRoleAccessControls);
  const finalDownloadCategoryViews = getViews(
    downloadCategories,
    permissions,
    defaultRoleAccessControls,
    'onlyAdminEditorCanChange',
  );
  const finalDownloadTypeViews = getViews(
    downloadTypes,
    permissions,
    defaultRoleAccessControls,
    'onlyAdminEditorCanChange',
  );

  const finalEmployeeViews = getViews(employees, permissions, defaultRoleAccessControls);
  const finalPresenterViews = getViews(
    presenters,
    permissions,
    defaultRoleAccessControls,
  );
  const finalEmployeeGroupCategoryViews = getViews(
    employeeGroupCategories,
    permissions,
    defaultRoleAccessControls,
    'onlyAdminEditorCanChange',
  );
  const finalEmployeeGroupViews = getViews(
    employeeGroups,
    permissions,
    defaultRoleAccessControls,
  );

  const finalEmployeeTypeViews = getViews(
    employeeTypes,
    permissions,
    defaultRoleAccessControls,
    'onlyAdminEditorCanChange',
  );

  const finalAwardTypeViews = getViews(
    awardTypes,
    permissions,
    defaultRoleAccessControls,
    'onlyAdminEditorCanChange',
  );

  const finalAwardViews = getViews(awards, permissions, defaultRoleAccessControls);
  const finalAwardCategoryViews = getViews(
    awardCategories,
    permissions,
    defaultRoleAccessControls,
    'onlyAdminEditorCanChange',
  );

  /*
    const finalC360GAEventViews = getViews(
      c360GaEvents,
      permissions,
      defaultRoleAccessControls,
      'onlyAdminCanChange',
    );
    */

  /* const finalAwardTypeViews = getViews(
        awardTypes,
        permissions,
        defaultRoleAccessControls,
        'onlyAdminEditorCanChange',
      ); */

  const finalLocationViews = getViews(
    locations,
    permissions,
    defaultRoleAccessControls,
    'onlyAdminEditorCanChange',
  );
  const finalFAQViews = getViews(faqs, permissions, defaultRoleAccessControls);
  const finalFAQCategoryViews = getViews(
    faqCategories,
    permissions,
    defaultRoleAccessControls,
    'onlyAdminEditorCanChange',
  );
  const finalFAQTypeViews = getViews(
    faqTypes,
    permissions,
    defaultRoleAccessControls,
    'onlyAdminEditorCanChange',
  );
  const finalEventViews = getViews(events, permissions, defaultRoleAccessControls);
  const finalEventTypeViews = getViews(
    eventTypes,
    permissions,
    defaultRoleAccessControls,
    'onlyAdminEditorCanChange',
  );
  const finalModalsViews = getViews(modals, permissions, defaultRoleAccessControls);
  const finalGenericContentViews = getViews(
    genericContents,
    permissions,
    defaultRoleAccessControls,
    'onlyAdminEditorCanChange',
  );
  const finalExtraContentViews = getViews(
    extraContents,
    permissions,
    defaultRoleAccessControls,
    'onlyAdminEditorCanChange',
  );

  const finalLocationCategoryViews = getViews(
    locationCategories,
    permissions,
    defaultRoleAccessControls,
    'onlyAdminEditorCanChange',
  );

  const finalLocationTypesViews = getViews(
    locationTypes,
    permissions,
    defaultRoleAccessControls,
    'onlyAdminEditorCanChange',
  );

  const finalCompanyViews = getViews(companies, permissions, defaultRoleAccessControls);
  const finalCompanyCategoryViews = getViews(
    companyCategories,
    permissions,
    defaultRoleAccessControls,
    'onlyAdminEditorCanChange',
  );

  const finalTagViews = getViews(
    tags,
    permissions,
    defaultRoleAccessControls,
    'onlyAdminEditorCanChange',
  );

  const finalBacklinkViews = getViews(
    backlinks,
    permissions,
    defaultRoleAccessControls,
    'onlyAdminEditorCanChange',
  );

  const finalTerritoryViews = getViews(
    territories,
    permissions,
    defaultRoleAccessControls,
    'onlyAdminCanChange',
  );

  const finalIconViews = getViews(
    icons,
    permissions,
    defaultRoleAccessControls,
    'onlyAdminCanChange',
  );

  const finalNavigationBarViews = getViews(
    navigationBars,
    permissions,
    defaultRoleAccessControls,
    'onlyAdminCanChange',
  );

  // console.log('finalGGIconsViews: ', finalTerritoryViews);
  const finalOverrideViews = getViews(
    overrides,
    permissions,
    defaultRoleAccessControls,
    'onlyAdminEditorCanChange',
  );
  const finalPageViews = getViews(
    pages,
    permissions,
    defaultRoleAccessControls,
    'onlyAdminEditorCanChange',
  );
  const finalActionBarViews = getViews(
    actionBars,
    permissions,
    defaultRoleAccessControls,
    'onlyAdminEditorCanChange',
  );
  const finalHeaderSectionViews = getViews(
    headerSection,
    permissions,
    defaultRoleAccessControls,
    'onlyAdminEditorCanChange',
  );
  const finalHeaderDropdownViews = getViews(
    headerDropdown,
    permissions,
    defaultRoleAccessControls,
    'onlyAdminEditorCanChange',
  );
  const finalFooterSectionViews = getViews(
    footerSection,
    permissions,
    defaultRoleAccessControls,
    'onlyAdminEditorCanChange',
  );

  const finalProductCategoryViews = getViews(
    productCategories,
    permissions,
    defaultRoleAccessControls,
    'onlyAdminEditorCanChange',
  );
  const finalCorporateStatementViews = getViews(
    corporateStatement,
    permissions,
    defaultRoleAccessControls,
    'onlyAdminEditorCanChange',
  );
  const finalCoreValuesViews = getViews(
    coreValues,
    permissions,
    defaultRoleAccessControls,
    'onlyAdminEditorCanChange',
  );

  const finalGlossaryViews = getViews(glossaries, permissions, defaultRoleAccessControls);

  const finalGlossaryItemViews = getViews(
    glossaryItems,
    permissions,
    defaultRoleAccessControls,
  );

  const finalUploadFilesViews = getViews(
    uploadFiles,
    permissions,
    defaultRoleAccessControls,
  );

  const roleAccessControls = { ...defaultRoleAccessControls };
  roleAccessControls[editor].edit = false;
  roleAccessControls[editor].create = false;
  roleAccessControls[admin].create = false;
  const finalUserViews = getViews(
    users,
    permissions,
    roleAccessControls,
    'onlyAdminCanChangeAndViewList',
  );

  const resourceViews = [];
  if (permissions === myGGEditor) {
    resourceViews.push(
      <Resource
        name="help-articles"
        icon={helpArticles.icon}
        options={{ label: translate('app.ggHelpArticles'), isMenuParent: true }}
      />,
      <Resource
        name="gg-help-center-articles"
        {...finalHelpArticleViews}
        // edit={permissions === 'Administrator' ? ArticleEdit : null}
        options={{
          label: translate('app.ggHelpArticles'),
          menuParent: 'help-articles',
        }}
      />,
      <Resource
        name="gg-help-center-article-categories"
        {...finalHelpArticleCategoryViews}
        // edit={permissions === 'admin' ? VisitorEdit : null}
        options={{
          label: translate('app.ggHelpArticleCategories'),
          menuParent: 'help-articles',
        }}
      />,

      <Resource
        name="gg-authors"
        {...finalAuthorViews}
        options={{ label: translate('app.ggAuthors') }}
      />,

      <Resource name="territories" options={{ label: translate('app.territories') }} />,
      <Resource name="users-permissions/roles" />,
      <Resource
        name="users"
        {...finalUserViews}
        options={{ label: translate('app.users') }}
      />,
      <Resource name="languages" />,
      <Resource name="upload/files" />,
    );
  } else {
    resourceViews.push(
      <Resource
        name="articles"
        icon={articles.icon}
        options={{ label: translate('app.articles'), isMenuParent: true }}
      />,
      <Resource
        name="gg-articles"
        {...finalArticleViews}
        // edit={permissions === 'Administrator' ? ArticleEdit : null}
        options={{ label: translate('app.ggArticles'), menuParent: 'articles' }}
      />,
      <Resource
        name="gg-article-categories"
        {...finalArticleCategoryViews}
        // edit={permissions === 'admin' ? VisitorEdit : null}
        options={{
          label: translate('app.ggArticleCategories'),
          menuParent: 'articles',
        }}
      />,
      <Resource
        name="products"
        icon={products.icon}
        options={{ label: translate('app.products'), isMenuParent: true }}
      />,
      <Resource
        name="gg-products"
        {...finalProductViews}
        // edit={permissions === 'admin' ? VisitorEdit : null}
        options={{ label: translate('app.ggProducts'), menuParent: 'products' }}
      />,
      <Resource
        name="lob-categories"
        {...finalLobCategoryViews}
        options={{ label: translate('app.lobCategories'), menuParent: 'products' }}
      />,
      <Resource
        icon={productCategories.icon}
        name="gg-product-categories"
        {...finalProductCategoryViews}
        options={{ label: translate('app.ggProductCategories'), menuParent: 'products' }}
      />,
      <Resource
        icon={employees.icon}
        name="gg-modals"
        {...finalModalsViews}
        options={{ label: translate('app.modals') }}
      />,
      <Resource
        name="downloads"
        icon={downloads.icon}
        options={{ label: translate('app.downloads'), isMenuParent: true }}
      />,
      <Resource
        name="gg-downloads"
        {...finalDownloadViews}
        options={{ label: translate('app.ggDownloads'), menuParent: 'downloads' }}
      />,
      <Resource
        name="gg-download-categories"
        {...finalDownloadCategoryViews}
        // edit={permissions === 'admin' ? VisitorEdit : null}
        options={{
          label: translate('app.ggDownloadCategories'),
          menuParent: 'downloads',
        }}
      />,
      <Resource
        name="gg-download-types"
        {...finalDownloadTypeViews}
        // edit={permissions === 'admin' ? VisitorEdit : null}
        options={{ label: translate('app.ggDownloadTypes'), menuParent: 'downloads' }}
      />,
      <Resource
        icon={uploadFiles.icon}
        name="upload/files"
        {...finalUploadFilesViews}
        options={{ label: translate('app.uploadFiles') }}
      />,
      <Resource
        name="awards"
        icon={awards.icon}
        options={{ label: translate('app.awards'), isMenuParent: true }}
      />,
      <Resource
        name="gg-awards"
        {...finalAwardViews}
        options={{ label: translate('app.ggAwards'), menuParent: 'awards' }}
      />,
      <Resource
        name="gg-award-categories"
        {...finalAwardCategoryViews}
        // edit={permissions === 'admin' ? VisitorEdit : null}
        options={{ label: translate('app.ggAwardCategories'), menuParent: 'awards' }}
      />,
      <Resource
        name="gg-award-types"
        {...finalAwardTypeViews}
        // edit={permissions === 'admin' ? VisitorEdit : null}
        options={{ label: translate('app.ggAwardTypes'), menuParent: 'awards' }}
      />,
      <Resource
        icon={locations.icon}
        name="locations"
        options={{ label: translate('app.locations'), isMenuParent: true }}
      />,
      <Resource
        name="gg-locations"
        {...finalLocationViews}
        // edit={permissions === 'admin' ? VisitorEdit : null}
        options={{ label: translate('app.ggLocations'), menuParent: 'locations' }}
      />,
      <Resource
        name="gg-location-categories"
        {...finalLocationCategoryViews}
        // edit={permissions === 'admin' ? VisitorEdit : null}
        options={{
          label: translate('app.ggLocationCategories'),
          menuParent: 'locations',
        }}
      />,
      <Resource
        name="gg-location-types"
        {...finalLocationTypesViews}
        // edit={permissions === 'admin' ? VisitorEdit : null}
        options={{ label: translate('app.ggLocationTypes'), menuParent: 'locations' }}
      />,
      <Resource
        name="events"
        icon={events.icon}
        options={{ label: translate('app.events'), isMenuParent: true }}
      />,
      <Resource
        icon={events.icon}
        name="gg-events"
        {...finalEventViews}
        options={{ label: translate('app.ggEvents'), menuParent: 'events' }}
      />,
      <Resource
        name="gg-event-types"
        {...finalEventTypeViews}
        options={{ label: translate('app.ggEventTypes'), menuParent: 'events' }}
      />,
      <Resource
        icon={faqs.icon}
        name="faqs"
        options={{ label: translate('app.faqs'), isMenuParent: true }}
      />,
      <Resource
        name="gg-faqs"
        {...finalFAQViews}
        options={{
          label: translate('app.ggFaqs'),
          menuParent: 'faqs',
        }}
      />,
      <Resource
        name="gg-faq-categories"
        {...finalFAQCategoryViews}
        options={{
          label: translate('app.ggFaqCategories'),
          menuParent: 'faqs',
        }}
      />,
      <Resource
        name="gg-faq-types"
        {...finalFAQTypeViews}
        options={{ label: translate('app.ggFaqTypes'), menuParent: 'faqs' }}
      />,

      <Resource
        icon={ViewCompactIcon}
        name="layouts"
        options={{ label: translate('app.layouts'), isMenuParent: true }}
      />,
      <Resource
        icon={navigationBars.icon}
        name="gg-navigation-bars"
        {...finalNavigationBarViews}
        options={{ label: translate('app.ggNavigationBars'), menuParent: 'layouts' }}
      />,
      <Resource
        icon={actionBars.icon}
        name="gg-action-bars"
        {...finalActionBarViews}
        options={{ label: translate('app.ggActionBars'), menuParent: 'layouts' }}
      />,
      <Resource
        icon={headerSection.icon}
        name="gg-header-sections"
        {...finalHeaderSectionViews}
        options={{ label: translate('app.ggHeaderSection'), menuParent: 'layouts' }}
      />,
      <Resource
        icon={headerDropdown.icon}
        name="gg-header-dropdowns"
        {...finalHeaderDropdownViews}
        options={{ label: translate('app.ggHeaderDropdown'), menuParent: 'layouts' }}
      />,
      <Resource
        icon={footerSection.icon}
        name="gg-footer-sections"
        {...finalFooterSectionViews}
        options={{ label: translate('app.ggFooterSection'), menuParent: 'layouts' }}
      />,
      <Resource
        icon={employees.icon}
        name="employees"
        options={{ label: translate('app.employees'), isMenuParent: true }}
      />,
      <Resource
        name="gg-employees"
        {...finalEmployeeViews}
        // edit={permissions === 'admin' ? VisitorEdit : null}
        options={{ label: translate('app.ggEmployees'), menuParent: 'employees' }}
      />,
      <Resource
        name="gg-presenters"
        {...finalPresenterViews}
        // edit={permissions === 'admin' ? VisitorEdit : null}
        options={{ label: translate('app.ggPresenters'), menuParent: 'employees' }}
      />,
      <Resource
        name="gg-employee-group-categories"
        {...finalEmployeeGroupCategoryViews}
        // edit={permissions === 'admin' ? VisitorEdit : null}
        options={{
          label: translate('app.ggEmployeeGroupCategories'),
          menuParent: 'employees',
        }}
      />,
      <Resource
        name="gg-employee-groups"
        {...finalEmployeeGroupViews}
        // edit={permissions === 'admin' ? VisitorEdit : null}
        options={{ label: translate('app.ggEmployeeGroups'), menuParent: 'employees' }}
      />,
      <Resource
        name="gg-employee-types"
        {...finalEmployeeTypeViews}
        // edit={permissions === 'admin' ? VisitorEdit : null}
        options={{ label: translate('app.ggEmployeeTypes'), menuParent: 'employees' }}
      />,

      <Resource
        name="pages"
        icon={pages.icon}
        options={{ label: translate('app.pages'), isMenuParent: true }}
      />,
      <Resource
        icon={pages.icon}
        name="gg-pages"
        {...finalPageViews}
        options={{ label: translate('app.ggPages'), menuParent: 'pages' }}
      />,
      <Resource
        icon={overrides.icon}
        name="gg-overrides"
        {...finalOverrideViews}
        options={{ label: translate('app.ggOverrides'), menuParent: 'pages' }}
      />,
      <Resource
        name="glossary"
        icon={glossaries.icon}
        options={{ label: translate('app.glossaries'), isMenuParent: true }}
      />,
      <Resource
        name="gg-glossaries"
        {...finalGlossaryViews}
        icon={glossaries.icon}
        options={{ label: translate('app.ggGlossaries'), menuParent: 'glossary' }}
      />,
      <Resource
        name="gg-glossary-items"
        icon={glossaryItems.icon}
        {...finalGlossaryItemViews}
        options={{ label: translate('app.ggGlossaryItems'), menuParent: 'glossary' }}
      />,
      <Resource
        icon={companies.icon}
        name="companies"
        options={{ label: translate('app.companies'), isMenuParent: true }}
      />,
      <Resource
        name="gg-companies"
        {...finalCompanyViews}
        options={{ label: translate('app.ggCompanies'), menuParent: 'companies' }}
      />,
      <Resource
        name="gg-company-categories"
        {...finalCompanyCategoryViews}
        options={{
          label: translate('app.ggCompanyCategories'),
          menuParent: 'companies',
        }}
      />,
      <Resource
        icon={genericContents.icon}
        name="genericContents"
        options={{ label: translate('app.genericContents'), isMenuParent: true }}
      />,
      <Resource
        icon={genericContents.icon}
        name="gg-generic-content-sections"
        {...finalGenericContentViews}
        options={{
          label: translate('app.ggGenericContents'),
          menuParent: 'genericContents',
        }}
      />,
      <Resource
        icon={extraContents.icon}
        name="gg-extra-contents"
        {...finalExtraContentViews}
        options={{
          label: translate('app.ggExtraContents'),
          menuParent: 'genericContents',
        }}
      />,
      <Resource
        icon={corporateStatement.icon}
        name="gg-corporate-statements"
        {...finalCorporateStatementViews}
        options={{
          label: translate('app.ggCorporateStatement'),
          menuParent: 'genericContents',
        }}
      />,
      <Resource
        icon={coreValues.icon}
        name="gg-core-values"
        {...finalCoreValuesViews}
        options={{
          label: translate('app.ggCoreValues'),
          menuParent: 'genericContents',
        }}
      />,

      <Resource
        name="help-articles"
        icon={helpArticles.icon}
        options={{ label: translate('app.helpArticles'), isMenuParent: true }}
      />,
      <Resource
        name="gg-help-center-articles"
        {...finalHelpArticleViews}
        // edit={permissions === 'Administrator' ? ArticleEdit : null}
        options={{
          label: translate('app.ggHelpArticles'),
          menuParent: 'help-articles',
        }}
      />,
      <Resource
        name="gg-help-center-article-categories"
        {...finalHelpArticleCategoryViews}
        // edit={permissions === 'admin' ? VisitorEdit : null}
        options={{
          label: translate('app.ggHelpArticleCategories'),
          menuParent: 'help-articles',
        }}
      />,
      <Resource
        name="misc"
        icon={() => <MoreIcon />}
        options={{ label: translate('app.misc'), isMenuParent: true }}
      />,
      <Resource
        name="gg-icons"
        {...finalIconViews}
        options={{ label: translate('app.ggIcons'), menuParent: 'misc' }}
      />,
      <Resource
        name="gg-tags"
        {...finalTagViews}
        options={{ label: translate('app.ggTags'), menuParent: 'misc' }}
      />,
      <Resource
        name="gg-backlinks"
        {...finalBacklinkViews}
        options={{ label: translate('app.ggBacklinks'), menuParent: 'misc' }}
      />,
      <Resource
        name="gg-authors"
        {...finalAuthorViews}
        options={{ label: translate('app.ggAuthors'), menuParent: 'misc' }}
      />,

      <Resource
        name="territories"
        {...finalTerritoryViews}
        options={{ label: translate('app.territories') }}
      />,
      <Resource
        name="users"
        {...finalUserViews}
        options={{ label: translate('app.users') }}
      />,
      <Resource
        name="languages"
        // {...articles}
        // edit={permissions === 'admin' ? VisitorEdit : null}
        // options={{ label: translate('languages' }}
      />,

      <Resource
        name="users-permissions/roles"
        // {...articles}
        // edit={permissions === 'admin' ? VisitorEdit : null}
        // options={{ label: translate('languages' }}
      />,
      <Resource
        name="gg-company-listings"
        {...companyListings}
        // {...articles}
        // edit={permissions === 'admin' ? VisitorEdit : null}
        // options={{ label: translate('languages' }}
      />,
      <Resource
        name="c-360-wizard-listings"
        {...wizardListings}
        // {...articles}
        // edit={permissions === 'admin' ? VisitorEdit : null}
        // options={{ label: translate('languages' }}
      />,
      <Resource
        name="static-variables"
        // {...articles}
        // edit={permissions === 'admin' ? VisitorEdit : null}
        // options={{ label: translate('languages' }}
      />,
      <Resource name="gg-products*1" />,
    );
  }

  return (
    <BrowserRouter>
      <AdminUI
        layout={MyLayout}
        loginPage={MyLoginPage}
        logoutButton={MyLogoutButton}
        settings={SettingsPage}
        catchAll={NotFound}
        dashboard={Dashboard}
        customRoutes={customRoutes}
        theme={theme}
        // loading={loading}
        // title={<Title />}
      >
        {resourceViews}
      </AdminUI>
    </BrowserRouter>
  );
};

export default Routes;

/*
      <Resource
        name="c-360-ga-event-actions"
        {...finalC360GAEventViews}
        options={{ label: translate('app.c360GAEvents'), menuParent: 'misc' }}
      />,
  <Resource
      name="gg-award-types"
    // {...finalAwardTypeViews}
    // {...downloadCategories}
    // edit={permissions === 'admin' ? VisitorEdit : null}
    // options={{ label: translate('GG Award Types', menuParent: 'awards' }}
    />,
    */
