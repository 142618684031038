import { createMuiTheme } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';

const primaryColor = '#9C0059';//'#8D0E57'; // '#C70751';
const secondaryColor = '#31006F';//'#343590'; // '#332986'; // '#336699'; //

const lightTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    background: {
      default: '#ffffff', // '#f3fcf0', // '#F0F2F5' //'#f3fcf0'
      paper: '#ffffff', // '#f3fcf0', // '#F0F2F5', // '#f5f5f5', //'#f3fcf0'
      grid: '#ECEDEC', // '#f7fff7', // '#ECEDEC', // '#E4E6EB', // '#ECEDEC',
      banner: '#ECEDEC', // grey.A100, // grey[500]
    },
    error: {
      main: '#ad0505', // '#f44336',
    },
    warning: {
      main: '#F7CD86',
    },
    success: {
      main: '#35AF28', // '#B1FC9C',
    },
  },
  shape: {
    borderRadius: 8,
    borderWidth: 2,
  },
});

export default lightTheme;
