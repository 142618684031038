import { languageStorage } from '../utils/variables';

export const getLanguage = () => {
  const language =
    typeof window !== 'undefined' && localStorage.getItem(languageStorage)
      ? localStorage.getItem(languageStorage)
      : null;
  if (!language) return null;
  return language;
};

export const removeLanguage = () => {
  if (localStorage.getItem(languageStorage)) {
    localStorage.removeItem(languageStorage);
  }
  return null;
};

export const setLanguage = (language) => {
  if (typeof window !== 'undefined' && language) {
    localStorage.setItem(languageStorage, language);
  }
  return null;
};

export default {
  getLanguage,
  removeLanguage,
  setLanguage,
};
