import enMessages from './en';
import nlMessages from './nl';

export const en = enMessages;

export const availableLanguages = {
  en: 'English',
  // fr: 'Français',
  nl: 'Dutch',
  // es: 'Spanish',
};

export const allMessages = {
  en: enMessages,
  // fr: frMessages,
  nl: nlMessages,
  // es: frMessages,
};
