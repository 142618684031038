import light from './light';
import dark from './dark';
import getCustomTheme from './custom';
// import GetLinkTheme from './link';

const themeMap = {
  light,
  dark,
};

export const themeTypes = {
  light: 'light',
  dark: 'dark',
  custom: 'custom',
};

export const getThemeByName = (themeName) => {
  // console.log('themeName: ', themeName);
  let theme = themeMap[themeName] || light;
  // console.log('theme: ', theme);
  // const linkTheme = GetLinkTheme();
  // console.log('linkTheme: ', linkTheme);
  //   linkTheme ||
  theme = themeName?.startsWith(themeTypes.custom) ? getCustomTheme(theme) : theme;
  // console.log('theme2: ', theme);
  theme = {
    ...theme,
    overrides: {
      MuiTextField: {
        root: {
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(1),
          paddingLeft: theme.spacing(0),
          paddingRight: theme.spacing(0),
          paddingTop: theme.spacing(0),
          paddingBottom: theme.spacing(0),
          marginLeft: theme.spacing(0),
          marginRight: theme.spacing(0),
        },
      },
      MuiSelect: {
        root: {
          marginLeft: theme.spacing(0),
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        },
      },
      MuiInputLabel: {
        root: {
          marginLeft: theme?.spacing(0),
        },
      },
    },
  };
  return theme;
};

const formatColor = (_inputColor) => {
  const inputColor = _inputColor?.trim();
  if (inputColor?.startsWith('#', 0)) return inputColor;
  return `#${inputColor}`;
};

export const formatThemeFromForm = (values) => {
  return {
    palette: {
      name: values?.name,
      type: values?.type,
      primary: {
        main: formatColor(values?.primary),
      },
      secondary: {
        main: formatColor(values?.secondary),
      },
      success: {
        main: formatColor(values?.success),
      },
      warning: {
        main: formatColor(values?.warning),
      },
      error: {
        main: formatColor(values?.error),
      },
      background: {
        default: formatColor(values?.backgroundDefault),
        paper: formatColor(values?.backgroundPaper),
        grid: formatColor(values?.backgroundGrid),
      },
    },
    shape: {
      borderRadius: parseInt(values?.borderRadius, 10),
    },
    typography: {
      fontSize: parseInt(values?.fontSize, 10),
      htmlFontSize: parseInt(values?.htmlFontSize, 10),
      fontFamily: values?.fontFamily,
      fontWeightLight: parseInt(values?.fontWeightLight, 10),
      fontWeightRegular: parseInt(values?.fontWeightRegular, 10),
      fontWeightMedium: parseInt(values?.fontWeightMedium, 10),
      fontWeightBold: parseInt(values?.fontWeightBold, 10),
    },
  };
};

export const formatThemeToForm = (mytheme, myThemeName) => {
  return {
    name: myThemeName,
    type: mytheme?.palette?.type,
    primary: mytheme?.palette?.primary?.main,
    secondary: mytheme?.palette?.secondary?.main,
    error: mytheme?.palette?.error?.main,
    warning: mytheme?.palette?.warning?.main,
    success: mytheme?.palette?.success?.main,
    backgroundPaper: mytheme?.palette?.background?.paper,
    backgroundGrid: mytheme?.palette?.background?.grid,
    backgroundDefault: mytheme?.palette?.background?.default,
    borderRadius: parseInt(mytheme?.shape?.borderRadius, 10),
    fontSize: parseInt(mytheme?.typography?.fontSize, 10),
    htmlFontSize: parseInt(mytheme?.typography?.htmlFontSize, 10),
    fontFamily: mytheme?.typography?.fontFamily,
    fontWeightLight: mytheme?.typography?.fontWeightLight,
    fontWeightRegular: mytheme?.typography?.fontWeightRegular,
    fontWeightMedium: mytheme?.typography?.fontWeightMedium,
    fontWeightBold: mytheme?.typography?.fontWeightBold,
  };
};

export default {
  getThemeByName,
  themeTypes,
  formatThemeToForm,
  formatThemeFromForm,
};

/*
 MuiAutocomplete: {
        root: {
          padding: theme.spacing(0),
        },
        inputRoot: {
          padding: theme.spacing(0),
        },
        hasPopupIcon: {
          padding: theme.spacing(0),
        },
        MuiOutlinedInput: {
          root: {
            padding: theme.spacing(0),
          },
        },
      },
      */
