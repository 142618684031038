import React, { useContext, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import { useLocale, useSetLocale } from 'react-admin';
import { refreshView } from 'ra-core';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Menu,
  Container,
  makeStyles,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GTranslateIcon from '@material-ui/icons/GTranslate';
import { availableLanguages } from '../../../translations';
import styles from './style';
import { LanguageContext } from '../../../context';

const useStyles = makeStyles(styles);
const LocaleSwitcher = ({ type = 'menu' }) => {
  const { language, setLanguage } = useContext(LanguageContext);
  const dispatch = useDispatch();
  const classes = useStyles();
  const locale = useLocale();
  const setLocale = useSetLocale();
  const currentLanguageName = availableLanguages[language];

  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [locale, setLocale] = React.useState(initialLocale);
  // console.log('initialLocale: ', locale);

  useEffect(() => {
    if (locale !== language) {
      setLocale(language);
    }
  }, [language]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const changeLanguage = (newLocale) => {
    // console.log('newLocale: ', newLocale);
    // document.documentElement.lang = newLocale;
    setLocale(newLocale);
    setLanguage(newLocale);
    dispatch(refreshView());
    window.location.reload();
  };

  const handleMenuItemClick = (event, value) => {
    changeLanguage(value);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (type === 'menu') {
    return (
      <>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          startIcon={<GTranslateIcon />}
          endIcon={<ExpandMoreIcon />}
          className={classes.buttonText}
        >
          {currentLanguageName}
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem value="" disabled>
            Select a Language
          </MenuItem>
          {Object.keys(availableLanguages).map((mylocale) => {
            return (
              <MenuItem
                key={mylocale}
                selected={language === mylocale}
                onClick={(event) => handleMenuItemClick(event, mylocale)}
              >
                {`${availableLanguages[mylocale]} - ${mylocale}`}
              </MenuItem>
            );
          })}
        </Menu>
      </>
    );
  }

  return (
    <Container>
      <FormControl fullWidth variant="outlined">
        <InputLabel id="demo-simple-select-label">Language</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          name="locale"
          value={language}
          fullWidth
          onChange={(event) => changeLanguage(event.target.value)}
          label="Language"
        >
          {Object.keys(availableLanguages).map((mylocale) => {
            return (
              <MenuItem key={mylocale} value={mylocale} selected={language === mylocale}>
                {`${availableLanguages[mylocale]} - ${mylocale}`}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Container>
  );
};

export default LocaleSwitcher;

/*

  if (view === 'button') {
    return (
      <div>
        <div>Language</div>
        {Object.keys(availableLanguages).map((locale) => (
          <Button disabled={locale === defaultLocale} onClick={() => setLocale(locale)}>
            {availableLanguages[locale]}
          </Button>
        ))}
      </div>
    );
  }

  return (
    <div>
      <div>Language</div>
      <ul>
        {Object.keys(availableLanguages).map((locale) => (
          <li key={locale} onClick={() => setLocale(locale)} aria-hidden="true">
            {availableLanguages[locale]}
          </li>
        ))}
      </ul>
    </div>
  );


        <Button disabled={locale === 'en'} onClick={() => setLocale('en')}>
          French
        </Button>
const LanguageSwitcher = () => {
  const setLocale = useSetLocaleAndPreference();
};

*/
