import { themeStorage } from '../utils/variables';

export const getTheme = () => {
  const theme =
    typeof window !== 'undefined' && localStorage.getItem(themeStorage)
      ? localStorage.getItem(themeStorage)
      : null;
  if (!theme) return null;
  // console.log('themeName: ', themeName);
  return JSON.parse(theme);
};

export const removeTheme = () => {
  if (localStorage.getItem(themeStorage)) {
    localStorage.removeItem(themeStorage);
  }
  return null;
};

export const setTheme = (theme) => {
  if (typeof window !== 'undefined' && theme) {
    localStorage.setItem(themeStorage, JSON.stringify(theme));
  }
  return null;
};

export default {
  setTheme,
  removeTheme,
  getTheme,
};
