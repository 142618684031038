import { createMuiTheme } from '@material-ui/core';
// import grey from '@material-ui/core/colors/grey';

const darkPrimaryColor = '#FFBC63'; // '#FFE716'; // '#F5952C';
const darkSecondaryColor = '#FFFFFF'; // '#D7BE82'; // '#D74E09'; // '#C60751';// '#E76B74'; // ;

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: darkPrimaryColor,
    },
    secondary: {
      main: darkSecondaryColor,
    },
    background: {
      default: '#262626', // '#282C35', // '#0d0c0a',
      paper: '#404041', // '#282C35',
      grid: '#335C67', // '#332A86', // '#124E78', // '#282C35',
      banner: '#335C67', // grey[700],
    },
    error: {
      main: '#ad0505', // '#f44336',
    },
    warning: {
      main: '#F9A620',
    },
    success: {
      main: '#35AF28', // '#548C2F',
    },
  },
  shape: {
    borderRadius: 8,
    borderWidth: 2,
  },
});

export default darkTheme;
