import React, { useState } from 'react';
import { LanguageContext } from '../context';
import { languageStorage } from '../storage';

const Language = ({ children }) => {
  const curLanguage = languageStorage.getLanguage() || 'en'; // useContext(LanguageContext)?.language || 'en';

  const [language, setLanguage] = useState(curLanguage);

  // const language = 'nl';//localProvider.getLocale();

  return (
    <LanguageContext.Provider
      value={{
        language,
        setLanguage: (value) => {
          setLanguage(value);
          languageStorage.setLanguage(value);
        },
      }}
    >
      {children(language)}
    </LanguageContext.Provider>
  );
};

export default Language;
