const clean = (value) => {
  const FALSY_VALUES = ['', 'null', 'false', 'undefined'];
  if (!value || FALSY_VALUES.includes(value)) {
    return undefined;
  }
  return value;
};

const CMS_URL =
  clean(process.env.REACT_APP_ALTERNATE_STRAPI_DOMAIN) ||
  process.env.REACT_APP_STRAPI_DOMAIN;

const C360_ROUTER_URL =
  clean(process.env.REACT_APP_ALTERNATE_ROUTER_DOMAIN) ||
  process.env.REACT_APP_ROUTER_DOMAIN;
const C360_PWA_URL =
  clean(process.env.REACT_APP_ALTERNATE_PWA_DOMAIN) || process.env.REACT_APP_PWA_DOMAIN;

const WEBSITE_URL =
  clean(process.env.REACT_APP_ALTERNATE_PUBLIC_SITE_DOMAIN) ||
  process.env.REACT_APP_PUBLIC_SITE_DOMAIN;

export const domain = {
  strapi: CMS_URL,
  // strapi: 'http://localhost:1337',
  website: WEBSITE_URL,
  router: C360_ROUTER_URL,
  pwa: C360_PWA_URL,
};

export const tokenKey = 'token';
export const roleKey = 'role';
export const userIdKey = 'userId';
export const authorIdKey = 'authorId';

export const themeStorage = 'theme';
export const applyLinkTheme = 'applyLinkTheme';
export const customThemeStorage = 'customTheme';
export const languageStorage = 'language';

export const admin = 'administrator';
export const author = 'author';
export const editor = 'editor';
export const contributor = 'contributor';
export const viewer = 'viewer';
export const myGGEditor = 'mygg editor';
export const territoryPerPageCount = 100;

export const mutationMode = 'pessimistic';

export const uploadFields = [
  'images',
  'files',
  'file',
  'file_nl',
  'file_en',
  'avatar',
  'cover',
  'logo',
  'coverImage.source',
  'featuredImage.source',
  'detailImage.source',
  'avatarImage.source',
  'profile',
  'image.image',
  'socialMedia.Icon',
  'icon',
  'featuredImage_pwa.source.sm',
  'featuredImage_pwa.source.md',
  'featuredImage_pwa.source.lg',
  'flag',
  'svgIcon',
  'image.source',
  'socialMediaContent.cover',
  'bannerStaticItem.icon',
  'contents.source',
];

export const translationFields = [
  'title',
  'description',
  'content',
  'text',
  'proseMirrorContent',
  'caption',
  'actionText',
  'name',
  'subtitle',
  'file',
  'mission',
  'purpose',
  'jobTitle',
  'miniBio',
  'fullBio',
  'displayText',
  'locationListHeading',
  'label',
  'locationPreviewDetails',
  'address',
  'Name',
  'bottomActionMessage',
  'actionMessage',
  'subTitle',
  'feedbackFormTitle',
  'layoutCoverText',
];
