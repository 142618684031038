import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Button,
  ButtonGroup,
  Typography,
  Popover,
  useMediaQuery,
  FormControlLabel,
  FormControl,
  Switch,
  FormLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import NightsStayIcon from '@material-ui/icons/NightsStay';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import { ThemeContext } from '../../../context';

import { themeTypes, getThemeByName, formatThemeToForm } from '../../../themes';

import styles from './style';

const useStyles = makeStyles(styles);
const ThemeSwitcher = ({ view, onChangeTheme }) => {
  const { themeValue, setThemeValue } = useContext(ThemeContext);

  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const sendThemeToIframe = (newThemeName) => {
    const switchTheme = getThemeByName(newThemeName);
    const formTheme = formatThemeToForm(switchTheme, newThemeName);

    // postIframe({ theme: formTheme });
  };

  const setThemeFunction = (themeValueValue) => {
    // setAppThemeName(themeValueValue);
    setThemeValue(themeValueValue);
    // sendThemeToIframe(themeValueValue);
    onChangeTheme(themeValueValue);
  };

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    // console.log('fired');
    setAnchorEl(null);
  };

  const handleSwitch = (event) => {
    setThemeFunction(event.target.checked ? themeTypes.dark : themeTypes.light);
  };

  const handleRadioChange = (event) => {
    const newThemeName = event.target.value;
    setThemeFunction(newThemeName);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const circle = (
    <div className={classnames(classes.shape, classes.shapeCircle)}>
      <div className={classes.textCenter}>
        {themeValue === themeTypes.light ? 'L' : 'D'}
      </div>
    </div>
  );

  if (view === 'badge') {
    return (
      <>
        <Badge
          aria-describedby={id}
          className={classes.badge}
          color="secondary"
          overlap="circle"
          badgeContent=""
          onMouseEnter={matches ? handleClick : undefined}
          onFocus={matches ? handleClick : undefined}
        >
          {circle}
        </Badge>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          onExit={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          PaperProps={{ onMouseLeave: handleClose }}
        >
          <div className={classes.root}>
            <Typography className={classes.typography}>Choose your Theme:</Typography>
            <ButtonGroup color="secondary" aria-label="outlined secondary button group">
              <Button
                variant={themeValue === themeTypes.light ? 'contained' : 'outlined'}
                onClick={() => {
                  setThemeFunction(themeTypes.light);
                  return handleClose();
                }}
              >
                Light
              </Button>
              <Button
                variant={themeValue === themeTypes.dark ? 'contained' : 'outlined'}
                onClick={() => {
                  setThemeFunction(themeTypes.dark);
                  return handleClose();
                }}
              >
                Dark
              </Button>
            </ButtonGroup>
          </div>
        </Popover>
      </>
    );
  }

  if (view === 'radio') {
    return (
      <FormControl component="fieldset" className={classes.root}>
        <FormLabel component="legend">Theme Name:</FormLabel>
        <RadioGroup
          row
          aria-label="position"
          name="position"
          value={themeValue}
          onChange={handleRadioChange}
          className={classes.root}
        >
          <FormControlLabel
            value="light"
            control={<Radio color="primary" />}
            label="Light"
            labelPlacement="bottom"
          />
          <FormControlLabel
            value="dark"
            control={<Radio color="primary" />}
            label="Dark"
            labelPlacement="bottom"
          />
          <FormControlLabel
            value="custom"
            control={<Radio color="primary" />}
            label="Custom"
            labelPlacement="bottom"
          />
        </RadioGroup>
      </FormControl>
    );
  }

  // console.log(`Theme Names: ${themeValue}`);

  return (
    <FormControl component="fieldset" className={classes.root}>
      <FormControlLabel
        control={
          <Switch
            color="primary"
            checked={themeValue === themeTypes.dark}
            onChange={handleSwitch}
          />
        }
        label={
          themeValue === themeTypes.dark ? (
            <NightsStayIcon color="primary" />
          ) : (
            <Brightness4Icon color="primary" />
          )
        }
        classes={{ root: classes.alignCenter, label: classes.themeSwitchLabel }}
        labelPlacement="start" //! matches ? : 'top'
      />
    </FormControl>
  );
};

export default ThemeSwitcher;

ThemeSwitcher.propTypes = {
  view: PropTypes.oneOf(['switch', 'badge', 'radio']),
  onChangeTheme: PropTypes.func,
};
ThemeSwitcher.defaultProps = {
  view: 'switch',
  onChangeTheme: () => {},
};

/*

        
      <Button
        aria-describedby={id}
        variant="contained"
        color="primary"
        
      >
        Open Popover
      </Button>
      */
