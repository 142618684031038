import React, { useEffect } from 'react';
import { Show } from 'react-admin';
import { Redirect } from '../../components/common';

const CompanyListingShow = (props) => {
  const getRedirectUrl = (record) => {
    const redirectUrl = `/gg-companies/${record?.companyId}/show`;
    return redirectUrl;
  };
  return (
    <Show {...props}>
      <Redirect getRedirectUrl={getRedirectUrl} />
    </Show>
  );
};

export default CompanyListingShow;

/*

      {permissions === admin && (
        <Tab label="user.form.security" path="security">
          <TextField source="role" />
        </Tab>
      )}
      */
