/* eslint react/jsx-key: off */
import * as React from 'react';
import { useTranslate } from 'react-admin';

export default ({ record = null, edit = false, show = false, create = false }) => {
  const translate = useTranslate();
  if (record && edit) {
    return (
      <span>
        {translate('resources.wizardListings.editTitle', { title: record.title })}
      </span>
    );
  }
  if (record && show) {
    return (
      <span>
        {translate('resources.wizardListings.showTitle', { title: record.title })}
      </span>
    );
  }
  if (create) {
    return <span>{translate('resources.wizardListings.createTitle')}</span>;
  }
  return <span>{translate('resources.wizardListings.name')}</span>;
};
