import { resolveBrowserLocale } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { allMessages } from '../translations';

/*
const messages = {
  fr: () => import('../translations/fr.js').then((myMessages) => myMessages.default),
  nl: () => import('../translations/fr.js').then((myMessages) => myMessages.default),
  zh: () => import('../translations/fr.js').then((myMessages) => myMessages.default),
  pt: () => import('../translations/fr.js').then((myMessages) => myMessages.default),
  es: () => import('../translations/fr.js').then((myMessages) => myMessages.default),
  ar: () => import('../translations/fr.js').then((myMessages) => myMessages.default),
  en: () => import('../translations/en.js').then((myMessages) => myMessages.default),
};
*/

export default polyglotI18nProvider(
  (locale) => {
    // console.log('locale: ', locale);
    if (locale) {
      return allMessages[locale];
    }

    // Always fallback on english
    return allMessages.en;
  },
  resolveBrowserLocale(),
  { allowMissing: true, onMissingKey: (key, _, __) => key },
); // "en"
