import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const Redirect = (props) => {
  // console.log('props: ', props);
  const { record, getRedirectUrl } = props;
  // console.log('record: ', record);
  const history = useHistory();
  useEffect(() => {
    const redirectUrl = getRedirectUrl(record);
    history.replace(redirectUrl);
  }, []);
  return <div>Redirecting...</div>;
};

export default Redirect;

/*

      {permissions === admin && (
        <Tab label="user.form.security" path="security">
          <TextField source="role" />
        </Tab>
      )}
      */
