import React from 'react';
import { themeStorage, languageStorage } from '../storage';

export const ThemeContext = React.createContext({
  themeValue: themeStorage?.getTheme(),
  setThemeValue: () => {},
});

export const LanguageContext = React.createContext({
  language: languageStorage?.getLanguage() || 'en',
  setLanguage: () => {},
});
