import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      width: 200,
      margin: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      width: 0,
      overflowX: 'hidden',
      margin: 0,
    },
  },
}));

const Aside = () => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <div className={classes.root}>
      <Typography variant="h6">
        {translate('resources.wizardListings.aside.title')}
      </Typography>
      <Typography variant="body2">
        {translate('resources.wizardListings.aside.description')}
      </Typography>
    </div>
  );
};

export default Aside;
