import { createMuiTheme } from '@material-ui/core';
import { customThemeStorage } from '../storage';

const getCustomTheme = (defaultTheme) => {
  const customTheme = customThemeStorage.getCustomTheme();
  if (!customTheme) return defaultTheme;
  return createMuiTheme({
    palette: {
      type: customTheme?.palette?.type,
      primary: {
        main: customTheme?.palette?.primary?.main,
      },
      secondary: {
        main: customTheme?.palette?.secondary?.main,
      },
      error: {
        main: customTheme?.palette?.error?.main || '#f44336',
      },
      warning: {
        main: customTheme?.palette?.warning?.main || '#F7CD86',
      },
      success: {
        main: customTheme?.palette?.success?.main || '#B1FC9C',
      },
      background: {
        default: customTheme?.palette?.background?.default,
        paper: customTheme?.palette?.background?.paper,
        grid: customTheme?.palette?.background?.grid,
      },
    },
    shape: {
      borderRadius: parseInt(customTheme?.shape?.borderRadius, 10),
    },
    typography: {
      fontSize: parseInt(customTheme?.typography?.fontSize, 10),
      fontFamily: customTheme?.typography?.fontFamily,
    },
  });
};

export default getCustomTheme;

/*
  shape: {
    borderRadius: 40,
  },
  typography: {
    fontSize: 12,
  },
typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    fontSize: 12,
  },
  */
